// Import Swiper and modules
import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay, Thumbs } from 'swiper/modules';
import fslightbox from 'fslightbox';


// Install modules
Swiper.use([Navigation, Pagination, EffectFade, Autoplay, Thumbs]);

export function initSwiper() {
	const initSwiper = document.querySelectorAll('[data-swiper]');
	
	if(initSwiper) {
		initSwiper.forEach((element) => {
	
			let params = JSON.parse(element.dataset.swiper);

			new Swiper(element, params);

			element.addEventListener('mouseover', function(){
				element.classList.add('show-controls');
			})

			element.addEventListener('mouseout', function(){
				element.classList.remove('show-controls');
			})
		});
	}
}


export function initProductSwiper() {
	const thumbs = document.querySelector('.product-thumbs')
	const gallery = document.querySelector('.product-gallery')
	const imageLinks = document.querySelectorAll('.woocommerce-product-gallery a')
	const productGallery = document.querySelector('.woocommerce-product-gallery')

	if(productGallery) {
		if(thumbs) {
			const productThumbs = new Swiper(thumbs, {
				loop: true,
				spaceBetween: 10,
				slidesPerView: 5,
				freeMode: true,
				preventClick: true,
				watchSlidesProgress: true,

				navigation: {
					nextEl: ".woocommerce-product-gallery .swiper-button-next",
					prevEl: ".woocommerce-product-gallery .swiper-button-prev",
				},

			});

			const productSwiper = new Swiper(gallery, {
				loop: true,
				spaceBetween: 10,
				thumbs: {
					swiper: productThumbs,
				},
				
				navigation: {
					nextEl: ".woocommerce-product-gallery .swiper-button-next",
					prevEl: ".woocommerce-product-gallery .swiper-button-prev",
				},
			});
		} else {
			// doesnt have any thumbnails
			const productSwiper = new Swiper(gallery, {
				loop: true,
				spaceBetween: 10,
				
				navigation: {
					nextEl: ".woocommerce-product-gallery .swiper-button-next",
					prevEl: ".woocommerce-product-gallery .swiper-button-prev",
				},
			});
		}
	

	}

	imageLinks.forEach((link) => {
	  link.setAttribute('data-fslightbox', 'gallery')
	})
	refreshFsLightbox();
}

