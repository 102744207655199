//  js imports
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import { lazyload } from './utilities/lazyload';
import { initSwiper, initProductSwiper } from './components/sliders';
import deviceAgent from './utilities/deviceagent';
import { nav_functions, toggleSearch } from './components/nav';
import fslightbox from "fslightbox";
import { videoInit } from './components/video';
import videojs from 'video.js';
import googleMap from './components/map';
//import { decode_ajax_add_to_cart } from './components/minicart';

$(function() {
    // lazy load assets
    lazyload();
    // device agent
    deviceAgent()
    // load nav functions
    nav_functions();
    toggleSearch();
    // init swiper sliders
    initSwiper();
    initProductSwiper();
    // Google Maps
    googleMap();
    // videos
    //videoInit();

})