export function nav_functions() {
    const mainheader = document.getElementById('mainHeader')
    let dropdowns = document.querySelectorAll('.dropdown')
    let lastScrollTop = mainheader.scrollTop

    const menuToggle = document.getElementById('mobileMenuToggle');
    const closeMobile = document.getElementById('closeMobileMenu');
    const searchToggle = document.querySelector('.search-toggle');
    const search  = document.getElementById('header-search-form');

    // add active class to header on dropdown hover for red bottom border
    dropdowns.forEach((dropdown) => {
        dropdown.addEventListener('mouseover', function() {
            mainheader.classList.add('active')
        })
        dropdown.addEventListener('mouseout', function() {
            mainheader.classList.remove('active')
        })

    })

    // detect scroll direction, add appropriate class to main header to hide and show on up scroll
    window.addEventListener('scroll', function() {
        var st = document.documentElement.scrollTop
        if (st > lastScrollTop) {
            // downscroll
            console.log('scrolling down')
            mainheader.classList.remove('up')
            mainheader.classList.add('down')
        } else if (st < lastScrollTop) {
            // upscroll
            console.log('scrolling up')
            mainheader.classList.add('up')
            mainheader.classList.remove('down')
        } // else was horizontal scroll
        lastScrollTop = st <= 0 ? 0 : st
    })

    menuToggle.addEventListener('click', function(e) {
        e.preventDefault();
        this.classList.toggle('open');
    })
    closeMobile.addEventListener('click', function(e) {
        e.preventDefault();
        menuToggle.classList.toggle('open');
    })
    searchToggle.addEventListener('click', function(e) {
        e.preventDefault();
        search.classList.add('show');
    })

    document.addEventListener('offcanvas.bs.hide', function() {
       menuToggle.classList.toggle('open');
    })
}

export function toggleSearch() {
    const searchToggle = document.querySelector('.search-toggle')
    const headerSearch = document.querySelector('.header-search')
    const svg = searchToggle.querySelector('#search-svg')
    const svgHref = svg.getAttribute('href') // original

    let newHref;

    console.log(svgHref)

    searchToggle.addEventListener('click', () => {
        searchToggle.classList.toggle('open')
        headerSearch.classList.toggle('open')


        if(searchToggle.classList.contains('open')) {
            newHref = '#expand'
        } else {
            newHref = svgHref
        }

        svg.setAttribute('href',newHref);

    })
}