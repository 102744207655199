export default function () {
	const googleMap = document.querySelectorAll('.google-map')

	if (typeof google === 'object' && typeof google.maps === 'object') {
		googleMap.forEach((gmap) => {
			let map = initMap(gmap);
		});
	}
}

function initMap($el) {
	var $markers = $el.querySelectorAll('.marker');
	var mapArgs = {
		zoom: parseInt($el.dataset.zoom) || 16,
		mapTypeId: 'roadmap',
	};
	var map = new google.maps.Map($el, mapArgs);
	map.markers = [];
	$markers.forEach((marker) => {
		initMarker(marker, map);
	});
	centerMap(map);
	return map;
}

function initMarker($marker, map) {
	var lat = $marker.dataset.lat;
	var lng = $marker.dataset.lng;
	var latLng = {
		lat: parseFloat(lat),
		lng: parseFloat(lng),
	};

	var icon = {
		url: 'data:image/svg+xml;utf-8, <svg viewBox="0 0 24 34" xmlns="http://www.w3.org/2000/svg" width="24" height="34" background-color="%23ffffff00"><path d="M0 12C0 21.7 12 33.7 12 33.7 12 33.7 24 21.7 24 12 24 5.4 18.6 0 12 0 5.4 0 0 5.4 0 12Z" fill="%23e73336"/><path d="M7.3 12C7.3 9.4 9.4 7.3 12 7.3 14.6 7.3 16.7 9.4 16.7 12 16.7 14.6 14.6 16.7 12 16.7 9.4 16.7 7.3 14.6 7.3 12Z" fill="%23fff"/></svg>',
	};
	var marker = new google.maps.Marker({
		position: latLng,
		map: map,
		icon: icon,
	});
	map.markers.push(marker);
	if ($marker) {
		// var infowindow = new google.maps.InfoWindow({
		// 	content: $marker,
		// });
		// google.maps.event.addListener(marker, 'click', function () {
		// 	infowindow.open(map, marker);
		// });
	}
}
function centerMap(map) {
	var bounds = new google.maps.LatLngBounds();
	map.markers.forEach(function (marker) {
		bounds.extend({
			lat: marker.position.lat(),
			lng: marker.position.lng(),
		});
	});
	if (map.markers.length == 1) {
		map.setCenter(bounds.getCenter());
	} else {
		map.fitBounds(bounds);
	}
}
